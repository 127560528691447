
import VueSlickCarousel from 'vue-slick-carousel';
import LandingServiceSlideshowSlide from './LandingServiceSlideshowSlide.vue';
import IconPlanActive from '~/components/icons/IconPlanActive.vue';
import IconMakeActive from '~/components/icons/IconMakeActive.vue';
import IconSourceActive from '~/components/icons/IconSourceActive.vue';
import IconBrandActive from '~/components/icons/IconBrandActive.vue';
import IconDeliverActive from '~/components/icons/IconDeliverActive.vue';
import IconStoreActive from '~/components/icons/IconStoreActive.vue';
import IconChevronBig from '~/components/icons/IconChevronBig.vue';
import IconChevron from '~/components/icons/IconChevron.vue';
import IconArrowRight from '~/components/icons/IconArrowRight.vue';

export default {
  components: { VueSlickCarousel, LandingServiceSlideshowSlide, IconPlanActive, IconMakeActive, IconSourceActive, IconBrandActive, IconDeliverActive, IconStoreActive, IconChevronBig, IconChevron, IconArrowRight },
  data () {
    return {
      current: 0
    };
  },
  computed: {

  },
  methods: {
    nextSlide () {
      if (typeof this.$refs.slider.next === 'function') {
        this.$refs.slider.next();
      }
    },
    prevSlide () {
      if (typeof this.$refs.slider.prev === 'function') {
        this.$refs.slider.prev();
      }
    },
    updateSlide (_o, n) {
      this.current = n;
    }
  }

};
