
import IconPlanActive from '~/components/icons/IconPlanActive.vue';
export default {
  components: { IconPlanActive },
  props: {
    image: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    length: {
      type: String,
      required: true
    },
    crop: {
      type: String,
      default: 'right',
      validator: value => ['right', 'edges', 'faces'].includes(value)
    }
  },
  computed: {
    mobileImage () {
      return `${this.image}?ar=1:1.25&fit=crop&crop=${this.crop}`;
    },
    tabletImage () {
      return `${this.image}?ar=1:1&fit=crop&crop=${this.crop}`;
    }
  }

};
