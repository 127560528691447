
const { default: IconWrapper } = require('./IconWrapper.vue');

export default {
  components: { IconWrapper },
  props: {
    alwaysBig: {
      type: Boolean,
      default: false
    },
    giant: {
      type: Boolean,
      default: false
    }
  }

};
